@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap");

* {
    font-family: 'Open Sans', sans-serif;
    margin: 0;
}

.app {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .submitButton {
    width: 100px;
    margin: 10px;
    padding: 10px;
    border: none;
    border-radius: 10px;
    background-color: #42BC47;
    color: white;
    cursor: pointer;
  }
  
  .error {
    font-size: 12px;
    color: crimson;
  }
  
  .success {
    font-size: 12px;
    color: green;
  }