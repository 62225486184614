
  .login,
  .home {
    padding: 20px;
    border: 1px solid lightgray;
    border-radius: 10px;
  }
  
  form {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  
  input {
    height: 30px;
    margin: 10px;
    border: none;
    border-bottom: 1px solid lightgray;
  }
  
  input:focus {
    outline: none;
  }
  