@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap);
* {
    font-family: 'Open Sans', sans-serif;
    margin: 0;
}

.app {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .submitButton {
    width: 100px;
    margin: 10px;
    padding: 10px;
    border: none;
    border-radius: 10px;
    background-color: #42BC47;
    color: white;
    cursor: pointer;
  }
  
  .error {
    font-size: 12px;
    color: crimson;
  }
  
  .success {
    font-size: 12px;
    color: green;
  }

  .login,
  .home {
    padding: 20px;
    border: 1px solid lightgray;
    border-radius: 10px;
  }
  
  form {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  
  input {
    height: 30px;
    margin: 10px;
    border: none;
    border-bottom: 1px solid lightgray;
  }
  
  input:focus {
    outline: none;
  }
  
